
    import Vue from "vue";
    import Component from "vue-class-component";
    import * as toastr from "toastr";
    import store from "@/store/store";
    import ApiButton from "@/components/ApiButton.vue";
    import SurveyPageComponent from "@/components/Survey/Page.vue";
    import apiClient from "@/stuff/ApiClient";
    import router, { beforeRouteLeave } from "@/router/router";
    import eventBus from "@/stuff/EventBus";
    import { SupplierResponseSet } from "@/model/SupplierResponseSet";
    import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
    import { IStatusChangedArguments } from "@/model/StatusChangedArguments";
    import { ResponseSetStatus } from "@/model/Enums";

    @Component({
        components: { ApiButton, SurveyPageComponent },
        beforeRouteLeave
    })
    export default class Survey extends Vue {

        mounted() {
            store.dispatch("loadUserList");
            const srsID = +this.$router.currentRoute.params.srsID;
            this.load(srsID);

            // set own reactive property whenever global dirty flag changes
            eventBus.$on("dirtyness-changed", (isDirty: boolean) => {
                this.isDirty = isDirty;
            });

            eventBus.$on("server-data-changed", (what: string, id: number, userID: string) => {
                console.log(`#-#-# SIGNALR - Survey.vue - what=${what}  id=${id}`);
            });

        }

        //
        // -- properties
        //

        isDirty: boolean = false; // we need a reactive property AND a global flag - so we have TWO flags (for now)

        supplierResponseSet = new SupplierResponseSet();

        surveyQuestionOptions: SurveyQuestionOptions = new SurveyQuestionOptions(true, ResponseSetStatus.None);

        get statusText(): string {
            if(this.supplierResponseSet.status === ResponseSetStatus.None) return "- - -";
            if(this.supplierResponseSet.status === ResponseSetStatus.AwaitingCompletion) return "Awaiting completion";
            return ResponseSetStatus[this.supplierResponseSet.status]; // just get enum text
        }

        get statusAlertCssClass(): string {
            let variant = "info";
            if (this.supplierResponseSet.status === ResponseSetStatus.Submitted) variant = "warning";
            if (this.supplierResponseSet.status === ResponseSetStatus.Accepted) variant = "success";
            if (this.supplierResponseSet.status === ResponseSetStatus.Rejected) variant = "danger";
            return `alert alert-${variant}`;
        }
        
        //
        // -- events
        //

        // triggered when user submits, rejects or accepts (button on StatusSetter.vue)
        // (actually, only accept or reject - only supplier can submit from supplierZone/modules.vue)
        async onStatusChanged(args: IStatusChangedArguments) {
            console.log("#### Survey - onStatusChanged ", args);
            this.supplierResponseSet.status = args.status;
            this.supplierResponseSet.rejectReason = args.rejectReason || "";
            this.supplierResponseSet.acceptReason = args.acceptReason || "";
            if(args.status === ResponseSetStatus.Accepted) {
                this.supplierResponseSet.activeFrom = args.activeFrom;
                this.supplierResponseSet.expires = args.expires;
            }
            this.surveyQuestionOptions.responseSetStatus = args.status;
            await apiClient.post("api/SupplierResponse/SaveResponseSet", this.supplierResponseSet);
            toastr.success("Status updated");
        }

        //
        // -- methods
        //

        goToSupplier() {  
            router.push(`/supplier/${this.supplierResponseSet.supplierID}?goToTab=modules`);
        }

        private async load(srsID: number) {
            const data = await apiClient.get(`/api/supplierResponse/load?id=${srsID}`);
            console.log(`Survey.vue load - /api/supplierResponse/load?id=${srsID} - response:`, data);
            this.supplierResponseSet.update(data);
            this.surveyQuestionOptions.responseSetStatus = this.supplierResponseSet.status;

            const surveyPage: SurveyPageComponent = this.$refs.surveyPage as SurveyPageComponent;
            // this would get called via watcher in Page.vue if supplierResponseSet changed - but it didn"t (?)
            surveyPage.loadSurvey();
        }
    }
